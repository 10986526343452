import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import * as _canvas2 from "@rive-app/canvas";

var _canvas = "default" in _canvas2 ? _canvas2.default : _canvas2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: !0
});
var e = _react,
    t = _canvas;

function n(e) {
  return e && "object" == typeof e && "default" in e ? e : {
    default: e
  };
}

var r = n(e),
    i = function () {
  return i = Object.assign || function (e) {
    for (var t, n = 1, r = arguments.length; n < r; n++) for (var i in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, i) && (e[i] = t[i]);

    return e;
  }, i.apply(this || _global, arguments);
};

function a(e, t) {
  var n = {};

  for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && t.indexOf(r) < 0 && (n[r] = e[r]);

  if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
    var i = 0;

    for (r = Object.getOwnPropertySymbols(e); i < r.length; i++) t.indexOf(r[i]) < 0 && Object.prototype.propertyIsEnumerable.call(e, r[i]) && (n[r[i]] = e[r[i]]);
  }

  return n;
}

var o = function () {
  function e() {}

  return e.prototype.observe = function () {}, e.prototype.unobserve = function () {}, e.prototype.disconnect = function () {}, e;
}();

var u = globalThis.ResizeObserver || o,
    c = void 0 !== globalThis.ResizeObserver,
    s = !c;

function f(t) {
  var n = e.useState({
    width: 0,
    height: 0
  }),
      r = n[0],
      i = n[1];
  e.useEffect(function () {
    if ("undefined" != typeof window) {
      var e = function () {
        i({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };

      return s && (e(), window.addEventListener("resize", e)), function () {
        return window.removeEventListener("resize", e);
      };
    }
  }, []);
  var a,
      o,
      f,
      l = e.useRef(new u((a = function (e) {
    c && i({
      width: e[e.length - 1].contentRect.width,
      height: e[e.length - 1].contentRect.height
    });
  }, o = 0, f = 0, function () {
    for (var e = this || _global, t = [], n = 0; n < arguments.length; n++) t[n] = arguments[n];

    clearTimeout(f), f = window.setTimeout(function () {
      return a.apply(e, t);
    }, o);
  })));
  return e.useEffect(function () {
    var e = l.current;
    return t.current && c && e.observe(t.current), function () {
      e.disconnect(), t.current && c && e.unobserve(t.current);
    };
  }, [t, l]), r;
}

function l() {
  var e = "undefined" != typeof window && "number" == typeof window.devicePixelRatio ? window.devicePixelRatio : 1;
  return Math.min(Math.max(1, e), 3);
}

function d(e) {
  var t = e.setContainerRef,
      n = e.setCanvasRef,
      o = e.className,
      u = void 0 === o ? "" : o,
      c = e.style,
      s = a(e, ["setContainerRef", "setCanvasRef", "className", "style"]),
      f = i({
    width: "100%",
    height: "100%"
  }, c);
  return r.default.createElement("div", i({
    ref: t,
    className: u
  }, !u && {
    style: f
  }), r.default.createElement("canvas", i({
    ref: n,
    style: {
      verticalAlign: "top",
      width: 0,
      height: 0
    }
  }, s)));
}

var h = {
  useDevicePixelRatio: !0,
  fitCanvasToArtboardHeight: !1,
  useOffscreenRenderer: !0
};

function v(n, a) {
  void 0 === a && (a = {});

  var o = e.useRef(null),
      u = e.useRef(null),
      c = e.useState(null),
      s = c[0],
      v = c[1],
      p = e.useState({
    height: 0,
    width: 0
  }),
      w = p[0],
      g = p[1],
      y = e.useState({
    height: 0,
    width: 0
  }),
      b = y[0],
      m = y[1],
      R = f(u),
      O = function () {
    var t = l(),
        n = e.useState(t),
        r = n[0],
        i = n[1];
    return e.useEffect(function () {
      if ("undefined" != typeof window && "matchMedia" in window) {
        var e = function () {
          var e = l();
          i(e);
        },
            t = window.matchMedia("screen and (resolution: ".concat(r, "dppx)"));

        return t.addEventListener("change", e), function () {
          t.removeEventListener("change", e);
        };
      }
    }, [r]), r;
  }(),
      E = Boolean(n),
      x = function (e) {
    return Object.assign({}, h, e);
  }(a);

  function C() {
    if (u.current) {
      var e = function () {
        var e,
            t,
            n,
            r,
            i = null !== (t = null === (e = u.current) || void 0 === e ? void 0 : e.clientWidth) && void 0 !== t ? t : 0,
            a = null !== (r = null === (n = u.current) || void 0 === n ? void 0 : n.clientHeight) && void 0 !== r ? r : 0;

        if (s && x.fitCanvasToArtboardHeight) {
          var o = s.bounds;
          return {
            width: i,
            height: i * (o.maxY / o.maxX)
          };
        }

        return {
          width: i,
          height: a
        };
      }(),
          t = e.width,
          n = e.height;

      if (o.current && s) {
        var r = t !== w.width || n !== w.height;

        if (x.fitCanvasToArtboardHeight && r && (u.current.style.height = n + "px"), x.useDevicePixelRatio) {
          var i = t * O !== b.width || n * O !== b.height;

          if (r || i) {
            var a = O * t,
                c = O * n;
            o.current.width = a, o.current.height = c, o.current.style.width = t + "px", o.current.style.height = n + "px", m({
              width: a,
              height: c
            });
          }
        } else r && (o.current.width = t, o.current.height = n, m({
          width: t,
          height: n
        }));

        g({
          width: t,
          height: n
        }), s.startRendering();
      }

      s && s.resizeToCanvas();
    }
  }

  e.useEffect(function () {
    s && C();
  }, [s, R, O]);
  var P = e.useCallback(function (e) {
    if (e && n && E) {
      var r = x.useOffscreenRenderer,
          a = new t.Rive(i(i({
        useOffscreenRenderer: r
      }, n), {
        canvas: e
      }));
      a.on(t.EventType.Load, function () {
        o.current && v(a);
      });
    } else null === e && o.current && (o.current.height = 0, o.current.width = 0);

    o.current = e;
  }, [E]),
      j = e.useCallback(function (e) {
    u.current = e;
  }, []);
  e.useEffect(function () {
    var e = new IntersectionObserver(function (e) {
      e[0].isIntersecting ? s && s.startRendering() : s && s.stopRendering();
    });
    return o.current && e.observe(o.current), function () {
      e.disconnect();
    };
  }, [s]), e.useEffect(function () {
    return function () {
      s && (s.stop(), v(null));
    };
  }, [s]);
  var M = null == n ? void 0 : n.animations;
  e.useEffect(function () {
    s && M && (s.isPlaying ? (s.stop(s.animationNames), s.play(M)) : s.isPaused && (s.stop(s.animationNames), s.pause(M)));
  }, [M, s]);
  var T = e.useCallback(function (e) {
    return r.default.createElement(d, i({
      setContainerRef: j,
      setCanvasRef: P
    }, e));
  }, []);
  return {
    canvas: o.current,
    setCanvasRef: P,
    setContainerRef: j,
    rive: s,
    RiveComponent: T
  };
}

exports.default = function (e) {
  var t = e.src,
      n = e.artboard,
      o = e.animations,
      u = e.stateMachines,
      c = e.layout,
      s = e.useOffscreenRenderer,
      f = void 0 === s || s,
      l = a(e, ["src", "artboard", "animations", "stateMachines", "layout", "useOffscreenRenderer"]),
      d = v({
    src: t,
    artboard: n,
    animations: o,
    layout: c,
    stateMachines: u,
    autoplay: !0
  }, {
    useOffscreenRenderer: f
  }).RiveComponent;
  return r.default.createElement(d, i({}, l));
}, exports.useRive = v, exports.useStateMachineInput = function (n, r, i, a) {
  var o = e.useState(null),
      u = o[0],
      c = o[1];
  return e.useEffect(function () {
    function e() {
      if (n && r && i || c(null), n && r && i) {
        var e = n.stateMachineInputs(r);

        if (e) {
          var t = e.find(function (e) {
            return e.name === i;
          });
          void 0 !== a && t && (t.value = a), c(t || null);
        }
      } else c(null);
    }

    e(), n && n.on(t.EventType.Play, function () {
      e();
    });
  }, [n]), u;
}, Object.keys(t).forEach(function (e) {
  "default" === e || exports.hasOwnProperty(e) || Object.defineProperty(exports, e, {
    enumerable: !0,
    get: function () {
      return t[e];
    }
  });
});
export default exports;
export const __esModule = exports.__esModule,
      useRive = exports.useRive,
      useStateMachineInput = exports.useStateMachineInput;